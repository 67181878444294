// import './vendor/bootstrap';
// import 'lazySizes';
// import './app/forms';
// import './app/homepage';
// import initRecaptcha from "./app/forms";

// $(function () {
//     initRecaptcha();
// }


import './app/autoComplete'
import './app/forms'
import './app/modalOffers'
import './app/navbar'
