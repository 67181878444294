document.addEventListener("DOMContentLoaded",  function () {
    let btn = document.getElementById('fileBtn');
    let cv = document.getElementById('cv');
    let content = document.querySelector('.signIn__inputContent');
    let weight = document.querySelector('.signIn__weight');
    let jobsSelect = document.getElementById('jobsSelect')

    if (btn){
        btn.addEventListener('click', function (){
            let input = btn.parentNode.querySelector('input[type=file]');
            input.click()
        })
    }

    if (cv){
        cv.addEventListener('change', function (){
            const fichier = cv.files[0];

            if (fichier){
                const fileWeight = fichier.size;
                const fileWeightKo = fileWeight / 1024;
                const fileWeightMo = fileWeightKo / 1024;
                const fileWeightShow = fileWeightMo >= 1 ? `${fileWeightMo.toFixed(2)} Mo` : `${fileWeightKo.toFixed(2)} Ko`;
                if (weight) {
                    weight.textContent = `Taille fichier : ${fileWeightShow}`;
                }
                content.innerHTML = fichier.name
            }
        })
    }

    if (jobsSelect){
        jobsSelect.addEventListener('change' , ()=> {
            window.location = jobsSelect.value
        })
    }
})
