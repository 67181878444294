import autoComplete from "@tarekraafat/autocomplete.js/dist/autoComplete";
document.addEventListener("DOMContentLoaded",  function () {

    if (document.getElementById('autoComplete')){
        const url = window.location.origin + '/api/ville'
        const autoCompleteJS = new autoComplete({
            selector: "#autoComplete",
            placeHolder: "Code postal + ville",
            name: 'postal',
            data: {
                src: async () => {
                    // coucou
                    const results = await fetch(url).then(res => {
                        return res.json()
                    }).then(res => {
                        const names = []

                        for (const sug of res) {
                            names.push(sug.name)
                        }

                        return names
                    })

                    return results

                },
                cache: true,
            },
            resultsList: {
                element: (list, data) => {
                    if (!data.results.length) {
                        // Create "No Results" message element
                        const message = document.createElement("div");
                        // Add class to the created element
                        message.setAttribute("class", "no_result");
                        // Add message text content
                        message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
                        // Append message element to the results list
                        list.prepend(message);
                    }
                },
                noResults: true,
            },
            resultItem: {
                highlight: true,
            },
            events: {
                input: {
                    selection: (event) => {
                        const selection = event.detail.selection.value
                        autoCompleteJS.input.value = selection
                    },
                },
            },

        });
    }


})

