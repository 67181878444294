document.addEventListener("DOMContentLoaded",  function () {
    if (document.getElementById('modalOffer')){
        const modal = new bootstrap.Modal(document.getElementById('modalOffer'));
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (modal && urlParams.get('show')) {
            modal.show();
        }
    }

});